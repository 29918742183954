const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE'
const SET_ORDER_BY = 'SET_ORDER_BY'
const SET_IS_DESC = 'SET_IS_DESC'
const RESET_INITIAL_STATE_TABLE = 'RESET_INITIAL_STATE_TABLE';
const SET_TABLE_SEARCH_VALUE = 'SET_TABLE_SEARCH_VALUE';

export {
    SET_CURRENT_PAGE,
    SET_ORDER_BY,
    SET_IS_DESC,
    RESET_INITIAL_STATE_TABLE,
    SET_TABLE_SEARCH_VALUE
}