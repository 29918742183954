import { combineReducers, AnyAction, Dispatch } from 'redux';
// import { connectRouter } from 'connected-react-router';
// import { History } from 'history'

import filters from './filterReducer';
import app from './appReducer';
import leads from './leadReducer';
import table from './tableReducer';
import selectors from './selectorsReducer';
import { ThunkDispatch } from 'redux-thunk';

const createRootReducer = combineReducers({
    // router: connectRouter(history),
    filters,
    app,
    selectors,
    leads,
    table,
});

export type RootState = ReturnType<typeof createRootReducer>
export type ApplicationDispatch = ThunkDispatch<RootState, void, AnyAction> & Dispatch

export default createRootReducer;