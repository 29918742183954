import { createStore, applyMiddleware } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { createHashHistory } from 'history';
import thunk from 'redux-thunk';
import createRootReducer from './redux/reducers';
import { composeWithDevTools } from 'redux-devtools-extension';

export const history = createHashHistory();

const initialState = {};
const enhancers = [];
const middleware = [thunk, routerMiddleware(history)];

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = (window as any).__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = composeWithDevTools({
  trace: true,
  ...enhancers
})

export default createStore(
  createRootReducer,
  initialState,
  composedEnhancers(applyMiddleware(...middleware))
);
