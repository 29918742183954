import { IFilterReducerState, IReduxReducer } from 'common/types/redux/IReducer';
import moment from 'moment';
import {
    SET_ON_THE_BOOKS_FILTER,
    SET_ON_THE_BOOKS_LEADS,
    SET_TODAYS_LEADS,
    SET_MOMENTUM_LEADS,
    SET_ON_THE_CUSP_LEADS,
    SET_CHECK_IN_LEADS,
    SET_SEMESTER_FILTER_VALUE,
    SET_SEMESTER_OPTIONS,
    SET_CAMPUS_FILTER_VALUE,
    SET_ON_THE_BOOKS_FILTER_OPTIONS,
    SET_LEAD_STATUS_FILTER_VALUE,
    SET_TEAM_MEMBERS_FILTER_VALUE,
    SET_DEPOSITED_FILTER_VALUE,
    SET_DEPOSITED_OPTIONS,
    RESET_INITIAL_STATE_FILTER,
    SET_APPLICANT_KEYWORD,
    SET_PROGRAM_FILTER_VALUE,
    SET_ACADEMIC_LEVEL_FILTER_VALUE,
    SET_LEAD_DECISION_FILTER_VALUE,
    SET_FACTOR_RECALCULATE_VALUE,
    SET_SEGMENT_FILTER_VALUE,
    SET_FILTERS_LISTS_SELECTED_OPTIONS,
    SET_FILTERS_LISTS_OPTIONS
} from 'redux/constants/filterConstants'

const initialState = {
   onTheBooksFilterValue: [],
   onTheBooksFilterOptions: [],
   onTheBooksLeads: [],
   onTheBooksLeadsOriginal: [],
   todaysLeads: [],
   todaysLeadsOriginal: [],
   momentumLeads: [],
   onTheCuspLeads: [],
   checkInLeads: [],
   semesterFilterValue: '',
   semesterDropdownOptions: [],
   campusFilterValue: '',
   campusDropdownOptions: [],
   leadsStatusFilterValue:  '',
   leadDecisionFilterValue:  '',
   teamMembersFilterOptions: [],
   teamMemberFilterValue: '',
   programFilterValue: null,
   segmentFilterValue: null,
   academicLevelFilterValue: null,
   depositedFilterValue: null,
   depositedDropdownOptions: [],
   factorToReCalculate: false,
   applicantKeyWord: null,
   filtersListsOptions: [],
   filtersListsSelectedOptions: {},
} as IFilterReducerState

const reducer = (state = initialState, { payload, type }: IReduxReducer) => {
    switch (type) {
        case SET_ON_THE_BOOKS_FILTER:
           return {
               ...state,
               onTheBooksFilterValue: payload,
           }
        case SET_ON_THE_BOOKS_LEADS:
           return {
               ...state,
               onTheBooksLeads: payload,
           }
        case SET_TODAYS_LEADS:
           return {
               ...state,
               todaysLeads: payload,
           }
        case SET_MOMENTUM_LEADS:
            return {
                ...state,
                momentumLeads:payload,
            }
        case SET_ON_THE_CUSP_LEADS:
            return {
                ...state,
                onTheCuspLeads:payload,
            }
        case SET_CHECK_IN_LEADS:
            return {
                ...state,
                checkInLeads:payload,
            }
        case SET_SEMESTER_FILTER_VALUE:
           return {
                ...state,
                semesterFilterValue: payload,
           }
        case SET_SEMESTER_OPTIONS:
           return {
               ...state,
               semesterDropdownOptions: payload,
           }
        case SET_CAMPUS_FILTER_VALUE:
           return {
               ...state,
               campusFilterValue: payload,
           }
        case SET_ON_THE_BOOKS_FILTER_OPTIONS:
           return {
               ...state,
               onTheBooksFilterOptions: payload,
           }
        case SET_LEAD_STATUS_FILTER_VALUE:
           return {
               ...state,
               leadsStatusFilterValue: payload,
           }
        case SET_LEAD_DECISION_FILTER_VALUE:
           return {
               ...state,
               leadDecisionFilterValue: payload,
           }
        case SET_APPLICANT_KEYWORD:
           return {
               ...state,
               applicantKeyWord: payload,
           }
        case SET_TEAM_MEMBERS_FILTER_VALUE:
           return {
               ...state,
               teamMemberFilterValue: payload,
           }
        case SET_DEPOSITED_FILTER_VALUE: //this is for the deposited filter
            return {
                ...state,
                depositedFilterValue: payload,
            }
        case SET_DEPOSITED_OPTIONS: //this is for the deposited filter
            return {
                ...state,
                depositedDropdownOptions: payload,
            }
        case SET_PROGRAM_FILTER_VALUE:
           return {
               ...state,
               programFilterValue: payload,
           }
        case SET_SEGMENT_FILTER_VALUE:
            return {
                ...state,
                segmentFilterValue: payload,
            }
        case SET_ACADEMIC_LEVEL_FILTER_VALUE:
           return {
               ...state,
               academicLevelFilterValue: payload,
           }
        case SET_FACTOR_RECALCULATE_VALUE:
           return {
               ...state,
               factorToReCalculate: payload,
           }
        case RESET_INITIAL_STATE_FILTER:
           return {
               ...initialState,
           }
        case SET_FILTERS_LISTS_OPTIONS:
            return {
                ...state,
                filtersListsOptions: {
                    ...state.filtersListsOptions,
                    ...payload
                },
            }
        case SET_FILTERS_LISTS_SELECTED_OPTIONS:
            return {
                ...state,
                filtersListsSelectedOptions: {
                ...state.filtersListsSelectedOptions,
                [payload.filterName]: payload.value
            }
            }
        default:
            return state;
    }
};

export default reducer;