import { ILeadReducerState, IReduxReducer } from 'common/types/redux/IReducer';
import {
    SET_LEAD_STATUSES,
    SET_LEAD_STATUS_IS_LOADING,
    RESET_INITIAL_STATE_LEAD,
    SET_APPLICANT_CARD_CONTEXT_TO_SAVE,
    SET_APPLICANT_CARD_CONTEXT_BEFORE_LEAVE,
    SET_LEAD_DECISIONS,
    SET_LEADS_LIST_IS_LOADING,
} from 'redux/constants/leadConstants'

const initialState = {
    leadStatuses: [],
    leadDecisions: [],
    isLoading: false,
    allowApplicantContextCardSave: false,
    allowApplicantContextCardSaveBeforeLeave: false,
    leadsListIsLoading: true,
} as ILeadReducerState

const reducer = (state = initialState, { payload, type }: IReduxReducer) => {
    switch (type) {
        case SET_LEADS_LIST_IS_LOADING:
           return {
                ...state,
                leadsListIsLoading: payload,
           }
        case SET_LEAD_STATUSES:
           return {
                ...state,
                leadStatuses: payload,
           }
        case SET_LEAD_DECISIONS:
           return {
                ...state,
                leadDecisions: payload,
           }
        case SET_LEAD_STATUS_IS_LOADING:
           return {
                ...state,
                isLoading: payload,
           }
        case RESET_INITIAL_STATE_LEAD:
           return {
               ...initialState,
           }
        case SET_APPLICANT_CARD_CONTEXT_TO_SAVE:
           return {
               ...state,
               allowApplicantContextCardSave: payload
           }
        case SET_APPLICANT_CARD_CONTEXT_BEFORE_LEAVE:
           return {
               ...state,
               allowApplicantContextCardSaveBeforeLeave: payload
           }
        default:
            return state;
    }
};

export default reducer