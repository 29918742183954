const SET_LEAD_STATUSES = 'SET_LEAD_STATUSES';
const SET_LEAD_DECISIONS = 'SET_LEAD_DECISIONS';
const SET_LEAD_STATUS_IS_LOADING = 'SET_LEAD_STATUS_IS_LOADING';
const RESET_INITIAL_STATE_LEAD = 'RESET_INITIAL_STATE_LEAD';
const SET_APPLICANT_CARD_CONTEXT_TO_SAVE = 'SET_APPLICANT_CARD_CONTEXT_TO_SAVE';
const SET_APPLICANT_CARD_CONTEXT_BEFORE_LEAVE = 'SET_APPLICANT_CARD_CONTEXT_BEFORE_LEAVE';
const SET_LEADS_LIST_IS_LOADING = 'SET_LEADS_LIST_IS_LOADING';

export {
    SET_LEAD_STATUSES,
    SET_LEAD_DECISIONS,
    SET_LEAD_STATUS_IS_LOADING,
    RESET_INITIAL_STATE_LEAD,
    SET_APPLICANT_CARD_CONTEXT_TO_SAVE,
    SET_APPLICANT_CARD_CONTEXT_BEFORE_LEAVE,
    SET_LEADS_LIST_IS_LOADING,
}